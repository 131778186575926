import React from "react";
import "./Loading.css";
import { CircularProgress } from "@mui/material";
function Loading({ light, full }) {
  return (
    <div
      className={
        full
          ? light
            ? "loading-widget-light-full"
            : "loading-widget-full"
          : light
          ? "loading-widget-light"
          : "loading-widget"
      }
    >
      <CircularProgress sx={{"*":{color:"#ff2f01"}}}/>
    </div>
  );
}

export default Loading;
