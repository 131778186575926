import React from 'react'

function BloggerInformation({blogger, small}) {
  return (
    <div style={{display:"flex", gap:"1rem", justifyContent:"flex-start"  }}>
    <div className="bloger-image"
    style={{
        backgroundImage:`url(${blogger.image1.url})`,
        backgroundPosition:"center",
        backgroundSize:"cover",
        width:small?"18px":"50px",
        height:small?"18px":"50px",
        borderRadius:"50%"
    }}
    ></div>
    <p
      style={{fontSize:".8rem"}}
    >{blogger.name[0].text}</p>
</div>
  )
}

export default BloggerInformation