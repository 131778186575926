import React, { useState } from "react";
import "./ServiceCard.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ServicePopup from "views/Services/ServicePopup";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { ReactComponent as Expand } from "assets/svg/expand_content_FILL0_wght400_GRAD0_opsz24.svg";

function ServiceCard({ title, list, image, gear }) {
  const [isMore, setIsMore] = useState(false);

  return (
    <div className="service-bg" 
    onClick={() => setIsMore(!isMore)}
    
    >
      {/* {isMore ? ( */}
      <ServicePopup
        isOpen={isMore}
        paragraph={list}
        title={title}
        onClose={() => setIsMore(false)}
      >
        {/* <div
            className="image"
            style={{
              backgroundImage: `url(${image})`,
              height: "100px",
              width: "100%",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              position: "relative",
            }}
          ></div> */}
        {image}
      </ServicePopup>
      {/* ) : null} */}
      <div className="service-card">
        <div
          className="image --display-center-row"
          style={{
            backgroundImage: `url(${image})`,
            height: "100px",
            width: "100%",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative",
            right: gear ? "-10px" : "0px",
          }}
        >
          {image}
        </div>
        <h3 className="card-title">{title}</h3>
        <div className="underline"></div>
        <div className="more" >
          <Expand
            style={{
              filter: `invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg) brightness(102%) contrast(102%)`,
            }}
          />
        </div>
        <br />{" "}
      </div>
    </div>
  );
}

export default ServiceCard;
