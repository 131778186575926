export const urls={
    logo:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703659128/oncspv0vrnpqroq6jvrs.png",
    bbmi:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703661667/bemryqd4uyv4f53srxwm.png",
    yemen:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703659127/j2eephgdjnxtc9msbuc3.png",
    serum:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703659127/w58pp6dklyo4j8fmrazu.png",
    abay:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703661666/jlwd7pb1alrzharbrvrn.png",
    nusra:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703659128/wmiww8a4stt48pqbnwlt.png",
    kategna:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703659129/yepdhfwjudq3eztkngnr.png",
    moplaco:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703659129/jy12yo5unvbkljx4ov7a.png",
    rans:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703661666/pctoig5bfqmo11dvyzaf.png",
    tereza:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703660585/eswio0lwrkocw1easm94.png",
    ethioB:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703661704/jfl5awhxps1bhgygemmf.png",
    londonB:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703660585/xtfnpr8gxa6zgnogg7yt.png",

    // portifolio

    websitePortifolio:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703702771/jy8zkl2zfndaxmhx2vae.png",
    qrordering:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703702783/oloqxjvus2sfxyvsbzca.png",
    sociamediaPortifolio:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703702782/r03gpcvkilyackvcmi0i.png",
    certificateVerfication:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703702786/dsizt1hgww41rs3tew0a.png",
    onlineLearing:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703702777/oeo3m7malgkfmogl1dcq.png",
    consultancy:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703703098/ztvdzzkripxjf8otv9le.jpg",

    lionHero:"https://res.cloudinary.com/dyb0cdvhy/image/upload/v1703703359/n7nx2y897ox1sr6d8v4e.png"
}