import Preloader from "components/Preloader/Preloader";
import Homepage from "./views/Homepage";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Blogs from "views/Blogs";
import ScrollToTop from "utils/ScrollToTop";
import { useEffect } from "react";



const App = () => {
  const GoogleAnalytics = () => {
    const location = useLocation();
  
    useEffect(() => {
      if (window.gtag) {
        window.gtag('config', 'G-EV2TBSLCY3', {
          page_path: location.pathname,
        });
      }
    }, [location]);
  };

useEffect(() => {
  const script = document.createElement('script');

  script.src = "https://use.typekit.net/foobar.js";
  script.async = true;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  }
}, []);

  return (
    <div className="App">
      <Router> 
        <GoogleAnalytics/>
        <Preloader />
        <ScrollToTop/>
        <Routes> 
         <Route path="/" element={<Homepage />}/>
         <Route path="/blog" element={<Blogs />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
