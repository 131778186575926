import React from "react";
import "./TrustedBy.css"; 
import { urls } from "consts/images.js";
const height = "150px";
function TrustedBy() {
  return (
    <>
      <section className="trusted-by">
        <p className="text">OUR CLIENTS</p>
        <div className="carousel">
          <div className="carousel-child one">
            <img src={urls.tereza} alt="" />
            <img src={urls.yemen} alt="" />
            <img src={urls.nusra} alt="" />
            <img src={urls.bbmi} alt="" />
            <img src={urls.serum} alt="" />
            <img src={urls.moplaco} alt="" />
            <img
              style={{
                height: "fit-content",
              }}
              width={height}
              src={urls.ethioB}
              alt=""
            />
            <img src={urls.rans} alt="" />
            <img src={urls.londonB} alt="" />
            <img src={urls.abay} alt="" />
          </div>
          <div className="carousel-child two">
          <img src={urls.tereza} alt="" />
            <img src={urls.yemen} alt="" />
            <img src={urls.nusra} alt="" />
            <img src={urls.bbmi} alt="" />
            <img src={urls.serum} alt="" />
            <img src={urls.moplaco} alt="" />
            <img
              style={{
                height: "fit-content",
              }}
              width={height}
              src={urls.ethioB}
              alt=""
            />
            <img src={urls.rans} alt="" />
            <img src={urls.londonB} alt="" />
            <img src={urls.abay} alt="" />
          </div>
          <div className="carousel-child three">
          <img src={urls.tereza} alt="" />
            <img src={urls.yemen} alt="" />
            <img src={urls.nusra} alt="" />
            <img src={urls.bbmi} alt="" />
            <img src={urls.serum} alt="" />
            <img src={urls.moplaco} alt="" />
            <img
              style={{
                height: "fit-content",
              }}
              width={height}
              src={urls.ethioB}
              alt=""
            />
            <img src={urls.rans} alt="" />
            <img src={urls.londonB} alt="" />
            <img src={urls.abay} alt="" />
          </div>
        </div>
      </section>
    </>
  );
}

export default TrustedBy;
