import React from "react";
import "./Popup.css";
import { Dialog, DialogTitle } from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { ReactComponent as Collaps } from "assets/svg/collapse_content_FILL0_wght400_GRAD0_opsz24.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function Popup({ isOpen, onClose, title, paragraph, children, lastComponent }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      TransitionComponent={Transition}
      maxWidth={"xl"}
      sx={{
        "& .MuiPaper-root": {
          background: "#000",
        },
        "& .MuiBackdrop-root": {
          //   backgroundColor: "transparent",
        },
      }}
    >
      <div className="dialog-parent">
        <div className="dialog-top">
            <div className="close-icon" onClick={onClose}>
              <Collaps
                style={{
                  filter: `invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg) brightness(102%) contrast(102%)`,
                }}
              />
            </div>
            <DialogTitle>{title}</DialogTitle>
          </div>
        <div className="dialog-sub-parent">
          
          {children}
          {paragraph.map((body) => (
            <p className="detail-paragraph">{body.text}</p>
          ))}
          <p className="detail-paragraph">
          {lastComponent}</p>
        </div>
      </div>
    </Dialog>
  );
}

export default Popup;
