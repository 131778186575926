import React, { useState } from "react";
import "./BlogCard.css";
import Popup from "components/Popup/Popup";
import BloggerInformation from "./BloggerInformation";
function BlogCard({ blog }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="blog-card">
      <Popup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={blog.data.title[0].text}
        paragraph={blog.data.body}
        lastComponent={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              gap: "2rem",
              flexWrap: "wrap",
            }}
          >
            {blog.data.authors.map((author) => (
              <BloggerInformation blogger={author} />
            ))}
          </div>
        }
      >
        <img
          src={blog.data.image.url}
          alt=""
          style={{ maxWidth: "100%", maxHeight: "50dvh" }}
        />
      </Popup>
      <div
        className="img"
        onClick={() => setIsOpen(true)}
        src={blog.data.image.url}
        alt=""
        style={{
          backgroundImage: `url(${blog.data.image.url})`,
        }}
      />
      <div className="blog-titles" onClick={() => setIsOpen(true)}>
        <p>{blog.data.title[0].text}</p>
        <p className="authors">
          {blog.data.authors.map((author, index) => (
            <>{index!=0?" | ":""}{author.name[0].text}</>
          ))}
        </p>
      </div>
    </div>
  );
}

export default BlogCard;
