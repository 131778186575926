import React from 'react'
import Form from "components/form/Form.jsx";
import "./Contact.css"
import "views/Homepage.css"

function Contact() {
  return (
    < >
        <section className=" section-parent" id="contacts">
          <div className="get-in-touch" >
            <p className="section-title">
              <span>Get In Touch</span>
            </p>
            <Form />
          </div>
        </section>
    </ >
  )
}

export default Contact