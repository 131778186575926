import React from "react";  
import ClientsCard from "components/clients-card/ClientsCard";
import "./Clients.css";
import { urls } from "consts/images";
function Clients() {
  return (
    <>
      <section id="portifolio" className="clients section-parent">
        <p className="section-title">
          <span>PORTIFOLIO</span>
        </p>
        <div className="cards">
          <ClientsCard
            name={"Digital Marketing"}
            image={urls.sociamediaPortifolio}
            motion={"--portifoliomotion"}
          />
          <ClientsCard
            name={"QR Menu & Ordering"}
            image={urls.qrordering}
            motion={"--portifoliomotion-reverse"}
          />
          <ClientsCard
            name={"QR Certificate Verification"}
            image={urls.certificateVerfication}
            motion={"--portifoliomotion"}
          />
        </div>
        <br/>
        <br/>
        <br/>
        <div className="cards">
          <ClientsCard
            name={"Website Design"}
            image={urls.websitePortifolio}
            motion={"--portifoliomotion-reverse"}
          />
          <ClientsCard
            name={"Online Learning Plateform"}
            image={urls.onlineLearing}
            motion={"--portifoliomotion"}
          />
          <ClientsCard
            name={"Social Media Managment"}
            image={urls.sociamediaPortifolio}
            motion={"--portifoliomotion-reverse"}
          />
        </div>
      </section>
    </>
  );
}

export default Clients;
